<template>
  <div class="alarm">
    <div class="alarm-top">
      <van-nav-bar
        title="可燃有毒报警"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
      </van-nav-bar>
      <div ref="filterBoxWrapper" class="filter-box-wrapper">
        <van-search
          v-model="searchName"
          placeholder="请输入报警点位名称关键字"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div class="alarm-search">
          <div class="date-time-box">
            <date-time
              v-model="dateTime"
              :time-close="false"
              type="datetime"
              format="{y}-{m}-{d} {h}:{i}"
            />
          </div>
        </div>
        <div class="department-box">
          <div ref="departmentList" class="department-list">
            <span
              v-for="(item, index) in departmentList"
              ref="dept"
              :key="item.department"
              class="department-item"
              :class="{
                active: form.selectDeptIds.includes(item.department)
              }"
              @click="selectDept(item, index)"
              >{{ item.departmentName }}</span
            >
          </div>
          <div class="department-filter">
            <i class="iconfont iconfont-peizhi" @click="selectCon(3)"></i>
          </div>
        </div>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.index, item, index)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}个报警</div>
          <!-- <div class="condition-switch">
            <van-icon
              class-prefix="iconfont"
              name="guolv"
              @click="searchConditionPopup = !searchConditionPopup"
            />
          </div> -->
        </div>
      </div>
    </div>

    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="alarm-list"
      @refresh="onRefresh"
      @scroll.native="onScroll"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="item in alarmList"
          :key="item.id + item.handleStatus"
          class="alarm-item"
          @click="gotoAlarmDetail(item.id)"
        >
          <div class="alarm-header">
            <div class="alarm-title">
              <div class="alarm-name-box">
                <img
                  v-if="item.handleStatus === '0'"
                  class="iconfont-baojingyuzhi"
                  src="../../../assets/images/alarm.png"
                />
                <span class="alarm-name"
                  >{{ item.indicatorName }} ({{ item.indicatorNum }})</span
                >
              </div>
              <div class="status-box">
                <img
                  class="second-icon"
                  src="../../../assets/images/equipment.png"
                />
                <span class="equipment-name">{{
                  item.equipmentName || "-"
                }}</span>
                <span class="status-color-box">{{ item.alarmStatusName }}</span>
                <span class="status-color-box type-color">{{
                  item.indicatorTypeName
                }}</span>
              </div>
            </div>
            <div
              class="alarm-status"
              :style="
                `color: ${disposalStatusIconColorSwitch[item.checkStatus]}`
              "
            >
              <!--              <img :src="disposalStatusImgSrcSwitch(item.checkStatus)" />-->
              <van-icon
                :name="disposalStatusImgSrcSwitch(item.checkStatus)"
                class-prefix="iconfont"
                class="trouble-status-icon"
              />
            </div>
          </div>
          <div class="alarm-time">
            <span>
              <img class="second-icon" src="../../../assets/images/date.png" />
              <span class="date-text">{{ item.firstAlarmTime }}</span>
            </span>
            <span>
              <img class="second-icon" src="../../../assets/images/time.png" />
              <span class="date-text">{{ item.alarmTime }}</span>
            </span>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <SelectPopupGrade
      ref="selectPopupGrade1"
      v-model="popupVisibleObj[1].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visible"
      @change="v => popupGradeChange(v, 1)"
      @close="popupVisibleObj[1].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade2"
      :immediately="false"
      :title="popupVisibleObj[2].title"
      :list="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visible"
      @close="popupVisibleObj[2].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade5"
      v-model="popupVisibleObj[5].value"
      :multi="true"
      :immediately="false"
      :title="popupVisibleObj[5].title"
      :list="popupVisibleObj[5].list"
      :visible="popupVisibleObj[5].visible"
      @change="v => popupGradeChange(v, 5)"
      @close="popupVisibleObj[5].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade6"
      v-model="popupVisibleObj[6].value"
      :multi="true"
      :immediately="false"
      :title="popupVisibleObj[6].title"
      :list="popupVisibleObj[6].list"
      :visible="popupVisibleObj[6].visible"
      @change="v => popupGradeChange(v, 6)"
      @close="popupVisibleObj[6].visible = false"
    />
    <add-facility
      :visible="popupVisibleObj[2].visible"
      :title="popupVisibleObj[2].title"
      @close="popupVisibleObj[2].visible = false"
      @confirm="v => popupGradeChange(v, 2)"
    />
    <SelectPopupGrade
      ref="selectPopupGrade3"
      v-model="departmentIds"
      popup-height="75%"
      :is-show-active-bgc="false"
      :immediately="false"
      :multi="true"
      :is-show-footer="true"
      :is-show-confrim-icon="false"
      list-key="value"
      filter-key="tagName"
      placeholder="请输入机构标签"
      :title="popupVisibleObj[3].title"
      :list="popupVisibleObj[3].list"
      :visible.sync="popupVisibleObj[3].visible"
      @change="v => popupGradeChange(v, 3)"
      @reset="$refs.selectPopupGrade3.selectAll(true)"
      @sure="updateAttentionDept"
    >
      <div
        slot="content"
        slot-scope="scope"
        :class="{ active: scope.row.isActive }"
        class="dept-item"
      >
        <div class="dept-item__left">
          <span class="dept-name text-overflow">{{ scope.row.label }}</span>
          <span class="dept-tag">{{ scope.row.tagName }}</span>
        </div>
        <span class="dept-status">
          <i
            v-if="scope.row.isActive"
            class="iconfont iconfont-danxuan-yixuan"
          ></i>
          <i v-else class="iconfont iconfont-danxuan-weixuan"></i>
        </span>
      </div>
    </SelectPopupGrade>
    <SelectPopupGrade
      ref="selectPopupGrade4"
      v-model="popupVisibleObj[4].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[4].title"
      :list="popupVisibleObj[4].list"
      :visible="popupVisibleObj[4].visible"
      @change="v => popupGradeChange(v, 4)"
      @close="popupVisibleObj[4].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade7"
      v-model="popupVisibleObj[7].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[7].title"
      :list="popupVisibleObj[7].list"
      :visible="popupVisibleObj[7].visible"
      @change="v => popupGradeChange(v, 7)"
      @close="popupVisibleObj[7].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade8"
      v-model="popupVisibleObj[8].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[8].title"
      :list="popupVisibleObj[8].list"
      :visible="popupVisibleObj[8].visible"
      @change="v => popupGradeChange(v, 8)"
      @close="popupVisibleObj[8].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade8"
      v-model="popupVisibleObj[9].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[9].title"
      :list="popupVisibleObj[9].list"
      :visible="popupVisibleObj[9].visible"
      @change="v => popupGradeChange(v, 9)"
      @close="popupVisibleObj[9].visible = false"
    />
    <van-popup
      v-model="searchConditionPopup"
      position="bottom"
      class="filter-popup"
    >
      <div class="popup-content">
        <div>报警状态</div>
        <van-radio-group v-model="query.handleStatus" direction="horizontal">
          <van-radio
            v-for="item in alarmHandleStatus"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
        <div>报警类型</div>
        <van-radio-group v-model="query.alarmStatus" direction="horizontal">
          <van-radio
            v-for="item in alarmLevels"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
        <div>监测项</div>
        <van-radio-group v-model="query.indicatorType" direction="horizontal">
          <van-radio
            v-for="item in indicatorTypes"
            :key="item.value"
            :name="item.value"
            >{{ item.label }}</van-radio
          >
        </van-radio-group>
      </div>
      <div class="popup-footer">
        <van-button type="danger" class="reset-btn" @click="handleReset"
          >重置</van-button
        >
        <van-button type="info" class="handle-btn" @click="handleCondition"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import alarm from "@/api/alarm";
import { parseTime } from "@/utils";
import { mapState, mapMutations } from "vuex";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import {
  getDangerSelect,
  getIndicatorDic,
  getAttentionDeptList,
  updateAttentionDeptList
} from "@/api/base";
import {
  getIndicatorIdsSelect,
  getEnvGroupIndicatorIdsSelect
} from "@/api/warning";
import { BUSINESSTYPE } from "../../workticket/utils/constant";
import AddFacility from "@/components/AddFacility";

export default {
  name: "Alarm",
  components: {
    AddFacility,
    SelectPopupGrade
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      needRefreshList: state => state.alarm.needRefreshList
    }),
    disposalStatusImgSrcSwitch() {
      return checkStatus => {
        const obj = {
          0: "yihulue",
          1: "daiqueren",
          2: "daichuzhi",
          3: "daiheyan",
          4: "yiwancheng"
        };
        return obj[checkStatus];
      };
    },
    disposalStatusIconColorSwitch() {
      return {
        0: "#aeb3c0",
        1: "#5c7ba2",
        2: "#dea148",
        3: "#6448d7",
        4: "#8eb433"
      };
    }
  },
  data() {
    return {
      scTop: 0,
      departmentIds: [],
      departmentList: [],
      filterList: [
        {
          name: "工艺报警指标",
          type: "select",
          text: "",
          index: 5,
          isActive: false
        },
        {
          name: "环保指标",
          type: "select",
          index: 6,
          text: "",
          isActive: false
        },
        // {
        //   name: "气体",
        //   index: "gas",
        //   type: "radio",
        //   isActive: false
        // },
        {
          name: "重点指标",
          type: "radio",
          index: "major",
          isActive: false
        },
        {
          name: "重大危险源",
          type: "select",
          index: 1,
          text: "",
          isActive: false
        },
        {
          name: "监测项",
          type: "select",
          index: 4,
          text: "",
          isActive: false
        },
        {
          name: "监测设备设施",
          type: "device",
          index: 2,
          text: "",
          isActive: false
        },
        {
          name: "报警状态",
          type: "select",
          index: 7,
          text: "",
          isActive: false
        },
        {
          name: "报警类型",
          type: "select",
          index: 8,
          text: "",
          isActive: false
        },
        {
          name: "处置状态",
          type: "select",
          index: 9,
          text: "",
          isActive: false
        }
      ],
      popupVisibleObj: {
        1: {
          visible: false,
          title: "重大危险源",
          value: "",
          list: []
        },
        2: {
          visible: false,
          title: "监测设备设施",
          value: ""
        },
        3: {
          visible: false,
          title: "关注部门",
          list: []
        },
        4: {
          visible: false,
          title: "监测项",
          value: "",
          list: []
        },
        5: {
          visible: false,
          title: "工艺报警指标",
          value: "",
          list: []
        },
        6: {
          visible: false,
          title: "环保指标",
          value: "",
          list: []
        },
        7: {
          visible: false,
          title: "报警状态",
          value: "",
          list: []
        },
        8: {
          visible: false,
          title: "报警类型",
          value: "",
          list: []
        },
        9: {
          visible: false,
          title: "处置状态",
          value: "",
          list: []
        }
      },
      form: {
        checked: false,
        major: null,
        dangerName: "",
        poiName: "",
        departmentName: "",
        departmentId: "",
        indicatorType: "",
        selectDeptIds: []
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      dateTime: {
        start: "",
        end: ""
      },
      // 查询条件
      query: {
        size: 10,
        page: 1,
        name: "",
        indicatorId: "",
        orgCode: "", // 所属单位
        alarmStatus: "", // 报警类型
        indicatorType: "", // 监测项
        handleStatus: "", // 报警状态
        startTime: "",
        endTime: "",

        envType: 0, // 是否是重点污染排放
        dangerIdList: "", // 危险源id
        poiIdList: "", // 设施id
        subDepartment: true,
        department: "", // 部门id
        indicatorTypeList: "", // 传感器类型(检测项)
        fuzzyName: "",
        gasType: 1, // 是否气体检测0:否,1:是
        craft: "", // 工艺报警指标
        envGroupIndicatorIdList: [] // 环保指标
      },
      searchName: "",
      // 告警列表
      alarmList: [
        // {
        //   id: 1,
        //   handleStatus: 1,
        //   indicatorName: "indicatorName",
        //   indicatorNum: "indicatorNum",
        //   alarmStatusName: "alarmStatusName",
        //   indicatorTypeName: "indicatorTypeName",
        //   equipmentName: "equipmentName",
        //   firstAlarmTime: "time",
        //   alarmTime: "12"
        // }
      ],
      total: 0,
      // 监测项
      indicatorTypes: [],
      // 报警状态
      alarmHandleStatus: [],
      // 报警类型
      alarmLevels: [],
      searchConditionPopup: false
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        console.log("dateTime -> val", val);
        if (val.start && val.end) {
          this.query.startTime = this.formatDate(val.start);
          this.query.endTime = this.formatDate(val.end);
          this.handleCondition();
        }
        if (!val.start && !val.end) {
          this.query.startTime = "";
          this.query.endTime = "";
          this.handleCondition();
        }
      },
      deep: true
    },
    searchName(newV) {
      if (!this.isFirstSearch) {
        this.query.indicatorId = "";
      }
    }
  },
  created() {
    this.scrollFrom = "list";
    this.lastScrollTop = 0;
    this.query.orgCode = this.userInfo.orgCode;
    this.getSelect();
    // 如果是在点位详情过来的, 需要回显点位名称以及清空日期
    const { from, id, name, num } = this.$route.query;
    if (from && from === "indicator-detail") {
      // 用作判断是否是第一次查询, 便于后续在该页面重新查询搜索的时候清空id
      this.isFirstSearch = true;
      this.searchName = num;
      this.dateTime.start = "";
      this.dateTime.end = "";
      this.query.indicatorId = id; // "116879023912517632";
    } else {
      this.dateTime = {
        start: parseTime(new Date(), "{y}-{m}-{d}") + " 00:00",
        end: parseTime(Date.now(), "{y}-{m}-{d}") + " 23:59"
      };
    }
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    getTotal(params) {
      alarm.getAlarmRecordCount(params).then(res => {
        this.total = res;
      });
    },
    popupGradeChange(v, type, label) {
      console.log(v), console.log(type);
      const vObj = v ? v[0] : {};
      if (type === 1) {
        // 危险源
        // 如果是全部危险源
        if (vObj && vObj.value === -1) {
          this.query.dangerIdList = this.popupVisibleObj[type].list
            .filter(a => a.value !== -1)
            .map(a => a.value)
            .join(",");
        } else {
          this.query.dangerIdList = v.map(a => a.value).join(",");
        }
        this.popupVisibleObj[1].value = vObj?.value;
        this.form.dangerName = vObj?.label;
        this.filterList[3].text = this.form.dangerName;
      } else if (type === 4) {
        // 检测项
        this.query.indicatorTypeList = v.map(a => a.value).join(",");
        this.popupVisibleObj[4].value = this.query.indicatorTypeList;
        this.form.indicatorType = vObj ? vObj.label : "";
        this.filterList[4].text = this.form.indicatorType;
      } else if (type === 2) {
        // 设备设施
        this.query.poiIdList = v.value;
        this.form.poiName = v.label;
        this.filterList[5].text = v.label;
      } else if (type === 5 || type === 6) {
        // 工艺报警指标
        let r = [];
        if (v.length === 1) {
          r = v[0].indicatorIdList;
        } else if (v.length > 1) {
          r = v.reduce((a, b) => {
            return {
              indicatorIdList: a.indicatorIdList.concat(b.indicatorIdList)
            };
          }).indicatorIdList;
        } else {
          r = [];
        }
        if (type === 5) {
          this.query.craft = r ? r.join(",") : "";
          this.popupVisibleObj[5].value = v.map(a => a.id);
          this.filterList[0].text = v.map(a => a.groupName).join(",");
        } else if (type === 6) {
          this.popupVisibleObj[6].value = v.map(a => a.id);
          this.query.envGroupIndicatorIdList = r ? r.join(",") : "";
          this.filterList[1].text = v.map(a => a.groupName).join(",");
        }
      } else if (type === 7) {
        this.popupVisibleObj[7].value = vObj?.value;
        this.query.handleStatus = vObj?.value;
        this.filterList[6].text = vObj?.label;
      } else if (type === 8) {
        this.popupVisibleObj[8].value = vObj?.value;
        this.query.alarmStatus = vObj?.value;
        this.filterList[7].text = vObj?.label;
      } else if (type === 9) {
        this.popupVisibleObj[9].value = vObj?.value;
        this.query.checkStatus = vObj?.value;
        this.filterList[8].text = vObj?.label;
      }
      this.onSearch(this.searchName);
    },
    onScroll(e) {
      if (this.scrollFrom === "detail") {
        this.scrollFrom = "list";
        return;
      }
      const dom = e.target;
      const offsetT = this.lastScrollTop - dom.scrollTop;
      const isTop = offsetT < 0;
      let v = this.scTop;
      if (isTop) {
        // 向上滚动
        if (Math.abs(offsetT) >= 52) {
          this.scTop = -104;
        }
      } else {
        this.scTop = 0;
      }
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.lastScrollTop = dom.scrollTop;
      }, 20);
    },
    // 设置部门入参
    setDepartmentParams(arr) {
      this.form.selectDeptIds = arr;
      this.query.department = arr.join(",");
    },
    getAttentionDeptList() {
      return getAttentionDeptList({
        orgCode: this.userInfo.orgCode,
        cuserId: this.userInfo.id,
        businessType: BUSINESSTYPE.alarm
      }).then(res => {
        const l = res.filter(a => a.attentionStatus === 1);
        // l 是最新的 this.departmentList: 是旧的
        this.departmentList = l;
        const arr = [];
        this.form.selectDeptIds.forEach(item => {
          if (this.departmentList.find(a => a.department === item)) {
            arr.push(item);
          }
        });
        if (arr.length !== this.form.selectDeptIds.length) {
          this.setDepartmentParams(arr);
          this.onSearch(this.searchName);
        }
        this.departmentIds = this.departmentList.map(a => a.department);
        this.popupVisibleObj[3].list = res.map(a => {
          return {
            ...a,
            label: a.departmentName,
            value: a.department
          };
        });
      });
    },
    getSelect() {
      this.getSearchCondition();
      getDangerSelect().then(res => {
        this.popupVisibleObj[1].list = [
          { label: "全部危险源", value: -1 },
          ...res
        ];
      });
      getIndicatorDic().then(res => {
        this.popupVisibleObj[4].list = res;
      });
      this.getAttentionDeptList();
      getIndicatorIdsSelect({
        orgCode: this.userInfo.orgCode
      }).then(res => {
        const list = res.map(a => {
          return {
            ...a,
            label: a.groupName,
            value: a.groupId,
            id: a.groupId
          };
        });
        this.popupVisibleObj[5].list = list;
      });
      getEnvGroupIndicatorIdsSelect({
        orgCode: this.userInfo.orgCode
      }).then(res => {
        const list = res.map(a => {
          return {
            ...a,
            label: a.groupName,
            value: a.groupId,
            id: a.groupId
          };
        });
        this.popupVisibleObj[6].list = list;
      });
      alarm.getAlarmDisposalSltData().then(res => {
        this.popupVisibleObj[9].list = res;
      });
    },
    updateAttentionDept(r) {
      updateAttentionDeptList({
        orgCode: this.userInfo.orgCode,
        cuserId: this.userInfo.id,
        businessType: BUSINESSTYPE.alarm,
        departmentList: r.map(a => a.value)
      }).then(res => {
        // 更新成功关注部门之后需要重新获取列表获取最新状态
        this.getAttentionDeptList();
      });
    },
    formatDate(date) {
      return new Date(date).getTime();
    },
    onReset() {
      this.query.envType = 0; // 是否是重点污染排放
      this.query.dangerIdList = ""; // 危险源id
      this.query.poiIdList = ""; // 设施id
      this.query.department = ""; // 部门id
      this.query.indicatorTypeList = ""; // 传感器类型(检测项)
      this.form.checked = false;
      this.form.dangerName = "";
      this.form.poiName = "";
      this.form.departmentName = "";
      this.form.indicatorType = "";
      this.$refs.selectPopupGrade1.setActive("");
      this.$refs.selectPopupGrade2.setActive("");
      this.$refs.selectPopupGrade4.setActive("");
    },
    onLoad() {
      if (this.refreshing) {
        this.alarmList = [];
        this.refreshing = false;
      }
      this.getList();
    },
    // 选中部门
    selectDept(item, index) {
      const i = this.form.selectDeptIds.findIndex(a => a === item.department);
      if (i === -1) {
        this.form.selectDeptIds.push(item.department);
      } else {
        this.form.selectDeptIds.splice(i, 1);
      }
      this.setDepartmentParams(this.form.selectDeptIds);
      this.onSearch(this.searchName);
    },
    selectCon(index, item, i) {
      // 如果是单选
      if (item && item.type === "radio") {
        item.isActive = !item.isActive;
        if (item.index === "major") {
          // 重点指标
          this.query.major = item.isActive ? 1 : null;
          // 去查询列表
          this.onSearch(this.searchName);
        }
        return;
      }
      this.popupVisibleObj[index].visible = true;
    },
    getList() {
      this.finished = false;
      this.isFirstSearch = false;
      const { from, id, name } = this.$route.query;
      if (id) {
        this.query.indicatorId = id;
      }
      const q = Object.assign({}, this.query, { fuzzyName: this.searchName });
      alarm
        .getAlarmList(q)
        .then(data => {
          this.loading = false;
          if (data.list && data.list.length) {
            this.alarmList = this.alarmList.concat(data.list);
            this.query.page++;
          } else {
            this.finished = true;
          }
        })
        .catch(err => {
          this.loading = false;
          this.finished = false;
          this.loadError = true;
        });
      if (this.query.page === 1) {
        this.getTotal(q);
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    gotoAlarmDetail(id) {
      this.$router.push({ name: "AlarmDetail", params: { id } });
    },
    onSearch(val) {
      this.finished = false;
      // this.handleCondition();
      this.loading = true;
      this.query.page = 1;
      this.alarmList = [];
      this.getList();
    },
    onCancel() {
      this.finished = false;
      this.handleCondition();
    },
    onClickLeft() {
      history.go(-1);
      // this.$router.push({ path: "/" });
    },
    /**
     * 获取筛选条件（监测项、报警类型、报警状态）
     */
    getSearchCondition() {
      alarm.getAlarmHandleStatus().then(data => {
        this.alarmHandleStatus = data;
        this.popupVisibleObj[7].list = data;
      });
      alarm.getAlarmLevel().then(data => {
        this.alarmLevels = data;
        this.popupVisibleObj[8].list = data;
      });
    },
    /**
     * 重置监测项、报警类型、报警状态单选框值
     */
    handleReset() {
      this.query.indicatorType = "";
      this.query.alarmStatus = "";
      this.query.handleStatus = "";
    },
    /**
     * 筛选条件确认调用
     * @returns {Promise<void>}
     */
    async handleCondition() {
      this.query.page = 1;
      this.searchConditionPopup = false;
      this.alarmList = [];
      this.loading = true;
      this.getList();
    }
  },
  activated() {
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
    if (this.needRefreshList) {
      this.onSearch();
      this.setNeedRefreshList({ value: false });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "AlarmDetail") {
        vm.scrollFrom = "detail";
      } else {
        vm.scrollFrom = "list";
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    next();
  },
  mounted() {
    this.getSearchCondition();
  }
};
</script>

<style scoped lang="scss">
.alarm {
  position: relative;
  text-align: left;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .dept-item {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 14px;
    &.active {
      .dept-name {
        color: $--color-primary;
      }
    }
    &__left {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }
    .dept-name {
      display: inline-block;
      color: #2e2e4d;
      max-width: 100px;
    }
    .iconfont-danxuan-weixuan {
      color: #d4dbe3;
    }
  }
}
.alarm-top {
  position: relative;
  .van-nav-bar {
    z-index: 12;
  }
  .search-bar {
    padding-bottom: 0;
  }
  .filter-box-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: all 0.3s;
    z-index: 10;
  }
  .department-box {
    padding: 0 0 0 16px;
    display: flex;
    flex-direction: row;
    height: 32px;
    .department-list {
      height: 32px;
      font-size: 12px;
      color: #3b4664;
      flex: 1;
      overflow-x: scroll;
      white-space: nowrap;
      display: flex;
      align-items: center;
      &::-webkit-scrollbar {
        display: none;
      }
      .department-item {
        display: inline-block;
        padding: 10px 12px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.active {
          color: $--color-primary;
        }
      }
    }
    .department-filter {
      position: relative;
      width: 56px;
      min-width: 56px;
      font-size: 16px;
      color: $--color-primary;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconfont {
        margin-left: 12px;
      }
      &::before {
        position: absolute;
        content: "";
        left: 12px;
        top: 50%;
        width: 1px;
        height: 16px;
        background-color: #c7c9d0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  .filter-box {
    padding: 5px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;
    &::-webkit-scrollbar {
      display: none;
    }
    .filter-item {
      display: inline-block;
      border-radius: 10px;
      background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;
      &.select {
        position: relative;
        padding-right: 18px;
        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }
      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .search-condition {
    display: flex;
    // margin: 1vw 5.6vw 3vw 5.6vw;
    justify-content: center;
    .loading {
      display: inline-block;
    }
    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }
    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      padding: 12px 0 0;
    }
    .condition-switch {
      color: $--color-icon;
      i {
        font-size: 5vw;
      }
    }
  }
}
.alarm-search {
  // position: relative;
  margin: 0;
  // height: 14vh;
  .date-time-box {
    padding: 12px 16px 0 16px;
    background-color: #fff;
    .datetime {
      position: relative;
      margin: 0;
    }
  }
}
.alarm-list {
  /*height: 68vh;*/
  padding-top: 195px;
  flex: 1;
  overflow-y: auto;
  z-index: 9;
}
// .alarm-item:nth-of-type(1) {
//   margin-top: 0;
// }
.alarm-item {
  // margin: 5.6vw;
  // border-bottom: 1px solid rgba(235, 235, 235, 1);
  // padding-bottom: 2vh;
  padding: 11px 17px 13px 16px;
  border-bottom: 8px solid #eceef2;
  font-size: 4vw;
  .alarm-header {
    display: flex;
    .alarm-title {
      flex: 3;
      max-width: 70vw;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.1;
      }
      color: #3b4664;
      .alarm-name-box {
        margin: 0 0 10px;
        .iconfont-baojingyuzhi {
          margin: 0 4px 0 0;
          vertical-align: sub;
        }
        .alarm-name {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #3b4664;
          line-height: 20px;
        }
      }
      .status-box {
        padding: 0 0 0 3px;
        margin: 0 0 6px;
        .equipment-name {
          font-size: 12px;
          font-weight: 400;
          color: #3b4664;
          line-height: 17px;
          margin: 0 20px 0 0;
        }
        .status-color-box {
          display: inline-block;
          font-size: 11px;
          font-weight: 400;
          color: #8b4fee;
          line-height: 16px;
          padding: 0 6px 0;
          margin: 0 8px 0 0;
          background: #ede2ff;
          border: 1px solid #8b4fee;
          border-radius: 3px;
        }
        .type-color {
          color: #00b6d6;
          background: #d8feff;
          border: 1px solid #00b6d6;
        }
      }
      .second-icon {
        margin: 0 4px 0 0;
        vertical-align: middle;
      }
    }
    .alarm-status {
      flex: 1;
      /*font-size: 100vw;*/
      text-align: right;
      padding: 7px 0 0;

      .trouble-status-icon {
        display: inline-block;
        text-align: center;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 40px;
        &::after {
          display: inline-block;
          width: inherit;
          height: inherit;
        }
      }
    }
  }
  .alarm-time {
    color: #3b4664;
    padding: 0 0 0 3px;
    .second-icon {
      margin: 0 4px 0 0;
      vertical-align: middle;
    }
    .date-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin: 0 20px 0 0;
    }
  }
}
</style>
